import {APP_INITIALIZER, Provider} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {AppConfig} from './app-config';
import {AppConfigBuilder} from './app-config.builder';
import {AppConfigFetcher} from './app-config.fetcher';
import {AppConfigService} from './app-config.service';

const timeout = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
/**
 * On startup, load app-config.json and build {@link AppConfig}.
 */
export const loadAppConfigOnStartup = (): Provider => ({
  
  provide: APP_INITIALIZER,
  multi: true,
  deps: [AppConfigFetcher, AppConfigBuilder, AppConfigService, KeycloakService],
  useFactory:
    (
      fetcher: AppConfigFetcher,
      builder: AppConfigBuilder,
      service: AppConfigService,
      kyService: KeycloakService,
    ) =>
      () => {
        return fetcher.fetchConfigJson().then(async (jsonConfig) => {
          await kyService.init({
            config: {
              url: jsonConfig.APP_KEYCLOAK_URL || '',
              realm: jsonConfig.APP_DEFAULT_KEYCLOAK_REALM || '',
              clientId: jsonConfig.APP_KEYCLOAK_CLIENT_ID || '',
            },
            initOptions: {
              onLoad: 'login-required', // allowed values 'login-required', 'check-sso';
              flow: 'standard', // allowed values 'standard', 'implicit', 'hybrid';
            },
          });
          return jsonConfig;
        }).then(async (jsonConfig) => {
            let isLoggedIn = await kyService.isLoggedIn();
            while (!isLoggedIn) {
              await timeout(500);
 
              isLoggedIn = await kyService.isLoggedIn();
            }
            const token = await kyService.getToken();
            const url = jsonConfig['EDC_UI_ORCHESTRATOR_URL'] as string;
            const apiConfig = await firstValueFrom(
              fetcher.fetchConfigFromApi(url, token),
            );
            return {...jsonConfig, ...apiConfig} as Record<string, string | null>;
          })
          .then((json) => builder.buildAppConfig(json))
          .then((config: AppConfig) => service.setConfig(config))
      }
});
