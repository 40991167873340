import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {Observable, Subject, interval} from 'rxjs';
import {map, shareReplay, switchMap, takeUntil} from 'rxjs/operators';
import {Actions, Store} from '@ngxs/store';
import {KeycloakService} from 'keycloak-angular';
import {FetchToken, Logout, UpdateToken} from 'src/store/client/client-actions';
import {routes} from '../../app-routing.module';
import {AppConfigService} from '../../config/app-config.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );

  routes = routes;

  /**
   * constructor
   * @param {Title} titleService
   * @param {KeycloakService} keycloak
   * @param {BreakpointObserver} breakpointObserver
   * @param {AppConfigService} appConfigService
   * @param {MatDialog} dialog
   * @param {Store} store
   * @param {Actions} actions$
   */
  constructor(
    public titleService: Title,
    private keycloak: KeycloakService,
    private breakpointObserver: BreakpointObserver,
    public appConfigService: AppConfigService,
    public dialog: MatDialog,
    public store: Store,
    public actions$: Actions,
  ) {}

  /**
   * OnInit hook
   */
  ngOnInit(): void {
    this.store.dispatch(new FetchToken(this.keycloak));

    // interval that fires each 4.5 minutes to update the token
    interval(270 * 1000)
      .pipe(
        switchMap(() => this.store.dispatch(new UpdateToken(this.keycloak))),
        takeUntil(this.stop$),
      )
      .subscribe(() => {
        console.log('[navigation] token was updated');
      });
  }

  /**
   * Logout
   * @return void
   */
  logout(): void {
    this.store.dispatch(new Logout(this.keycloak));
  }

  /**
   * OnDestroy hook
   */
  ngOnDestroy(): void {
    this.stop$.complete();
    this.stop$.unsubscribe();
  }
}
